import React from "react";
import {
  MdOutlinePhone,
  MdOutlineLocationOn,
  MdOutlineMail,
  MdFacebook,
} from "react-icons/md";
import { BsTwitter, BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { GiRotaryPhone } from "react-icons/gi";

const Footer = () => {
  return (
    <footer className="bg-neutral-800 text-neutral-200 px-4 py-16 sm:px-10 sm:py-20 md:px-24">
      <div className=" mx-auto ">
        <section className="sm:flex sm:items-center sm:justify-between mb-24 sm:mb-16">
          <div className="mb-6 sm:mb-0 sm:w-[40%]">
            <h5 className="md:text-h5 font-lora text-mh3 leading-mh140 text-shades-0  sm:text-th4 sm:tracking-[0.01em]">
              Our Newsletter
            </h5>
            <p className="md:text-l3 text-ml3 leading-l140 font-raleway mt-1 sm:mt-2 sm:text-tl14">
              We weekly post news letter related to Ruby, Rails and Software
              Engineering in general.
            </p>
          </div>
          <div className="  flex space-x-4 sm:mt-0 mt-6">
            <div className="grow">
              <label
                htmlFor="newsletter-email"
                className="md:text-p2 block text-l6 leading-ml160 sm:text-tp2"
              >
                Email
              </label>
              <input
                type="email"
                className="h-6 bg-transparent border-b-2 border-neutral-200 w-full block"
                id="newsletter-email"
              />
            </div>
            <button className="self-end text-primary-300 border border-primary-700 border-dashed p-2 rounded-full h-10 w-10 flex justify-center items-center">
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                className="fill-primary-300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8719 32.6519L14 30.7466L25.4651 19.2815L14 7.8164L15.8719 5.91112L29.2422 19.2815L15.8719 32.6519Z"
                  fill="current"
                />
              </svg>
            </button>
          </div>
        </section>
        <section className="grid grid-cols-5 gap-y-12 md:gap-x-6  ">
          <div className="col-span-2 md:col-span-1 place-self-start">
            <img src="/assets/images/ideabreedLogoWhite.png" alt="" />
          </div>
          <div className="col-span-3 md:col-span-1 place-self-end md:place-self-start md:order-last">
            <h5 className=" text-mh3 font-lora  leading-mh140 md:text-h5 mb-2 md:mb-4 text-shades-0">
              Follow Us
            </h5>
            <span className="inline-flex space-x-6">
              <MdFacebook fontSize={"24"} />
              <BsTwitter fontSize={"24"} />
              <RiInstagramFill fontSize={"24"} />
              <BsLinkedin fontSize={"24"} />
            </span>
          </div>

          <div className="col-span-5 md:col-span-1">
            <h5 className="md:text-h5 mb-4 text-shades-0 text-mh3 leading-mh140 font-lora sm:text-th4">
              Useful Links
            </h5>
            <div className=" flex  flex-col space-y-4 font-raleway text-ml1 leading-l140 sm:text-tp2">
              <p>Home</p>
              <p>Blog</p>
              <p>Careers</p>
              <p>Work</p>
            </div>
          </div>
          <div className="col-span-5 md:col-span-1">
            <h5 className="md:text-h5 mb-2 text-shades-0 text-mh3 leading-mh140 font-lora sm:text-th4">
              Contact Us
            </h5>
            <div className=" flex  flex-col space-y-4 font-raleway text-ml1 leading-l140 sm:text-tp2">
              <p className="flex flex-col justify-start items-start">
                <span className="flex items-center justify-center">
                  <MdOutlinePhone className="inline mr-2" fontSize={20} />
                  9851266268
                </span>
                <span className="flex items-center justify-center">
                  <GiRotaryPhone className="inline mr-2" fontSize={20} />
                  01-478570
                </span>
                <span className="flex items-center justify-center">
                  <GiRotaryPhone className="inline mr-2" fontSize={20} />
                  01-4795170
                </span>
              </p>
              <p className="inline-flex">
                <MdOutlineLocationOn
                  className="mr-2 min-w-[20px]"
                  fontSize={20}
                />
                <span>
                  Pragati Nagar Road <br /> New Baneshwor, Kathmandu <br />{" "}
                  Nepal
                </span>
              </p>
              <p className="inline-flex">
                <MdOutlineMail
                  className="inline mr-2 min-w-[20px]"
                  fontSize={20}
                />
                <span>info@ideabreed.com.np</span>
              </p>
            </div>
          </div>
          <div className="col-span-5 md:col-span-1">
            <h5 className="md:text-h5 mb-4 text-shades-0 text-mh3 leading-mh140 font-lora sm:text-th4">
              Website
            </h5>
            <div className=" flex  flex-col space-y-4  sm:text-tp2 font-raleway text-ml1 leading-l140">
              <p>
                <a
                  href="https://www.ideabreed.com.np"
                  target="_blank"
                  rel="noreferrer"
                >
                  ideabreed.com.np
                </a>{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
