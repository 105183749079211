import styled from "styled-components";

import FormGroup, { PillSelect } from "../FormGroup";
const pillData = [
  { title: "Consulting" },
  { title: " Mobile App" },
  { title: "Academy" },
  { title: "Product Design" },
  { title: "Digital Signage" },
  { title: "Survey and Tower Setup" },
];
const pillDataBudget = [
  { title: "2-5k" },
  { title: "5-10k" },
  { title: "10-20k" },
  { title: "20-50k" },
  { title: ">50k" },
];

export default function WorkWithUs() {
  return (
    <StyledWorkWithUs className="md:mt-28 mt-14 md:w-4/5 md:pb-20 w-full">
      <PillSelect options={pillData} label={"I am interested in "} />
      <div className="md:mt-20 mt-14 flex flex-col gap-y-10">
        <FormGroup.Text label={"Your name"} id="name" name={"name"} />
        <FormGroup.Text
          type="email"
          label="Email Address"
          id="email"
          name="email"
        />
        <FormGroup.Text
          type="tel"
          label="Phone Number"
          id="phone"
          name="phone"
        />
      </div>

      <div className="md:mt-10  mt-14">
        <PillSelect options={pillDataBudget} label={"Porject Budged (Rs)"} />
      </div>

      <FormGroup.TextArea
        label={" Tell us about your project"}
        id="comment"
        name="comment"
        minRows={4}
        className="mt-14"
      />

      <button className="md:mt-16 mb-16 mt-14 border-2 text-ml3 active:border-2 font-raleway leading-l140 hover:bg-primary-900 hover hover:shadow-hirebtn  hover:text-shades-0  active:shadow-md focus:shadow-md border-primary-900 rounded-[30px] px-8 py-3 md:text-l2 text-primary-900 uppercase fade-out-right w-max">
        <span className="grow-animation uppercase">Hire us </span>
      </button>
    </StyledWorkWithUs>
  );
}
const StyledWorkWithUs = styled.div``;
