import { useState } from "react";
import { HeroPills } from "../reusable/Button/HeroPills";

const FormGroup = ({ label, id, children, className }) => {
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className={
            " font-raleway  md:text-p2 sm:text-tp2 sm:leading-tp180 sm:tracking-[0.02em] md:tracking-normal text-mp4 leading-p180 text-neutral-600"
          }
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
};

const Text = ({ label, type = "text", name, maxLength, className, id }) => {
  return (
    <FormGroup label={label} id={id} className={className}>
      <input
        type={type}
        id={id}
        name={name}
        maxLength={maxLength}
        className="h-10 md:h-14 sm:h-12    border-b  focus:border-b outline-none md:text-p2 text-mp2 font-raleway text-neutral-900 focus:border-neutral-700 active:border-none  block w-full sm:text-sm border-neutral-200"
      />
    </FormGroup>
  );
};

const TextArea = ({
  id,
  label,
  name,
  maxLength,
  className,
  defaultValue,
  ...rest
}) => {
  return (
    <FormGroup label={label} id={id} className={className}>
      <textarea
        rows={4}
        name={name}
        id={id}
        {...rest}
        className="  border-b block h-6 text-mp2 w-full md:text-p2 font-raleway text-neutral-700 sm:text-sm border-neutral-200 focus:border-neutral-800 outline-none"
        defaultValue={defaultValue && defaultValue}
      />
    </FormGroup>
  );
};
export const PillSelect = ({ id, label, options, active }) => {
  const [select, setSelect] = useState("");
  return (
    <>
      {label && (
        <p className="md:text-l1 text-ml1 sm:text-tl3 sm:tracking-[0.02em]  leading-l140 font-raleway text-neutral-800">
          {label}
        </p>
      )}
      <div className="md:mt-6 mt-4 w-3/4">
        <div className="flex gap-3  flex-wrap">
          {options?.map((pd, idx) => (
            <HeroPills
              key={idx}
              pill={pd}
              onClick={() => {
                setSelect(pd.title);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default Object.assign({ Text, TextArea });
