import styled from "styled-components";
/**
 * 

 */
export const HeroPills = ({ pill }) => {
  return (
    <HeroButton>
      <button className="md:py-2 py-[6px] px-4 hero_btn box-border whitespace-nowrap  bg-transparent z-10  hover:text-shades-0 overflow-hidden border-[0.5px]  focus:border-2 focus:border-neutral-900 text-ml4  sm:text-tl14 leading-l140 tracking-wide  md:px-6 focus:px-5 focus:py-1  relative md:text-l3 font-raleway text-neutral-800  shadow-sm rounded-3xl   border-neutral-100 transition duration-750">
        {pill.title}
      </button>
    </HeroButton>
  );
};

const HeroButton = styled.div`
  .hero_btn {
  }
  .hero_btn::before {
    content: "";
    height: 1px;
    width: 1px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #141414;
    transition: linear 300ms;
    border-radius: 50%;
  }
  .hero_btn:hover::before {
    transform: scale(300);
    opacity: 1;
  }
`;
