//intro data
export const ideaIntro = {
  title: "Who we are",
  description_1:
    "Idea Breed Technology is a software development company based in Nepal(evolving IT Hub; +05:45 UTC); that creates complex business-driven solutions, with a focus on innovation and transparency of actions, guaranteed product delivery, and ongoing evolution",
  description_2:
    "Idea Breed keens on bringing innovation to enterprises and emerging companies around the world. The headquarter of Idea Breed Technology is located in New Baneshwor, Kathmandu. We make software development easy for our clients, so that they are able to concentrate on therapid growth of their business.",
  stat: [
    {
      title: "Years in Business",
      data: "7+",
    },
    {
      title: "Products",
      data: "45",
    },
    {
      title: " In House Talent",
      data: "30",
    },
  ],
};
