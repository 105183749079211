import React from 'react';

const UIComponents = () => {
	return (
		<div className="w-4/5 mx-auto py-16 text-shades-100">
			<div>
				<h1 className="text-h1 text-shades-100 mb-8">Colors</h1>
				<div className="grid grid-cols-6 gap-8 my-8">
					<h2 className="text-h3">Neutral</h2>
					<div className="w-40 h-16 bg-neutral-50"></div>
					<div className="w-40 h-16 bg-neutral-100"></div>
					<div className="w-40 h-16 bg-neutral-200"></div>
					<div className="w-40 h-16 bg-neutral-300"></div>
					<div className="w-40 h-16 bg-neutral-400"></div>
					<div></div>
					<div className="w-40 h-16 bg-neutral-500"></div>
					<div className="w-40 h-16 bg-neutral-600"></div>
					<div className="w-40 h-16 bg-neutral-700"></div>
					<div className="w-40 h-16 bg-neutral-800"></div>
					<div className="w-40 h-16 bg-neutral-900"></div>
				</div>
				<div className="grid grid-cols-6 gap-8 my-8">
					<h2 className="text-h3">Primary</h2>
					<div className="w-40 h-16 bg-primary-50"></div>
					<div className="w-40 h-16 bg-primary-300"></div>
					<div className="w-40 h-16 bg-primary-500"></div>
					<div className="w-40 h-16 bg-primary-700"></div>
					<div className="w-40 h-16 bg-primary-900"></div>
				</div>
				<div className="grid grid-cols-6 gap-8 my-8">
					<h2 className="text-h3">Secondary</h2>
					<div className="w-40 h-16 bg-secondary-50"></div>
					<div className="w-40 h-16 bg-secondary-300"></div>
					<div className="w-40 h-16 bg-secondary-500"></div>
					<div className="w-40 h-16 bg-secondary-700"></div>
					<div className="w-40 h-16 bg-secondary-900"></div>
				</div>
				<div className="grid grid-cols-6 gap-8 my-8">
					<h2 className="text-h3 ">Shades</h2>
					<div className="w-40 h-16 bg-shades-0 outline outline-[1px] outline-neutral-500"></div>
					<div className="w-40 h-16 bg-shades-100"></div>
				</div>
			</div>
			<div>
				<h1 className=" text-shades-100 mb-8 text-h1">Type Scale</h1>
				<div className="grid grid-cols-[1fr_2fr_2fr] auto-cols-min">
					<h1 className="text-n1">N1</h1>
					<h1 className="text-n1">Ideabreed</h1>
					<h1 className="text-n1">fontsize: 72px</h1>
					<h1 className="text-h1">H1</h1>
					<h1 className="text-h1">Ideabreed</h1>
					<h1 className="text-h1">fontsize: 58px</h1>
					<h2 className="text-h2">H2</h2>
					<h2 className="text-h2">Ideabreed</h2>
					<h2 className="text-h2">fontsize: 48px</h2>
					<h3 className="text-h3">H3</h3>
					<h3 className="text-h3">Ideabreed</h3>
					<h3 className="text-h3">fontsize: 26px</h3>
					<h4 className="text-h4">H4</h4>
					<h4 className="text-h4">Ideabreed</h4>
					<h4 className="text-h4">fontsize: 22px</h4>
					<h5 className="text-h5">H5</h5>
					<h5 className="text-h5">Ideabreed</h5>
					<h5 className="text-h5">fontsize: 18px</h5>
					<h6 className="text-h6">H6</h6>
					<h6 className="text-h6">Ideabreed</h6>
					<h6 className="text-h6">fontsize: 16px</h6>
					<p className="text-l1">L1</p>
					<p className="text-l1">Ideabreed</p>
					<p className="text-l1">fontsize: 22px</p>
					<p className="text-l2">L2</p>
					<p className="text-l2">Ideabreed</p>
					<p className="text-l2">fontsize: 16px</p>
					<p className="text-l3">L3</p>
					<p className="text-l3">Ideabreed</p>
					<p className="text-l3">fontsize: 18px</p>
					<p className="text-l4">L4</p>
					<p className="text-l4">Ideabreed</p>
					<p className="text-l4">fontsize: 26px</p>
					<p className="text-l5">L5</p>
					<p className="text-l5">Ideabreed</p>
					<p className="text-l5">fontsize: 22px</p>
					<p className="text-l6">L6</p>
					<p className="text-l6">Ideabreed</p>
					<p className="text-l6">fontsize: 22px</p>
					<p className="text-p1">P1</p>
					<p className="text-p1">Ideabreed</p>
					<p className="text-p1">fontsize: 18px</p>
					<p className="text-p2">P2</p>
					<p className="text-p2">Ideabreed</p>
					<p className="text-p2">fontsize: 22px</p>
					<p className="text-p3">P3</p>
					<p className="text-p3">Ideabreed</p>
					<p className="text-p3">fontsize: 20px</p>
				</div>
			</div>
			<div>
				<h1 className="text-5xl text-shades-100 mb-8 text-h1">Icons</h1>
				<div className="grid grid-cols-7 gap-8">
					<img src="/assets/svgs/react.svg" alt="" />
					<img src="/assets/svgs/python.svg" alt="" />
					<img src="/assets/svgs/flutter.svg" alt="" />
					<img src="/assets/svgs/rubyonrails.svg" alt="" />
					<img src="/assets/svgs/ios.svg" alt="" />
					<img src="/assets/svgs/android.svg" alt="" />
					<img src="/assets/svgs/node.svg" alt="" />
					<img src="/assets/svgs/signages.svg" alt="" />
					<img src="/assets/svgs/tower.svg" alt="" />
					<img src="/assets/svgs/academy.svg" alt="" />
					<img src="/assets/svgs/seo.svg" alt="" />
					<img src="/assets/svgs/productdesign.svg" alt="" />
					<img src="/assets/svgs/mobileapp.svg" alt="" />
					<img src="/assets/svgs/codescan.svg" alt="" />
					<img src="/assets/svgs/technologyconsulting.svg" alt="" />
					<img src="/assets/svgs/discover.svg" alt="" />
					<img src="/assets/svgs/demo.svg" alt="" />
					<img src="/assets/svgs/develop.svg" alt="" />
					<img src="/assets/svgs/deploy.svg" alt="" />
					<img src="/assets/svgs/committed.svg" alt="" />
					<img src="/assets/svgs/hiringprocess.svg" alt="" />
					<img src="/assets/svgs/timevalueofmoney.svg" alt="" />
					<img src="/assets/svgs/view.svg" alt="" />
					<img src="/assets/svgs/environment.svg" alt="" />
					<img src="/assets/svgs/salary.svg" alt="" />
					<img src="/assets/svgs/bonus.svg" alt="" />
					<img src="/assets/svgs/growth.svg" alt="" />
					<img src="/assets/svgs/allowance.svg" alt="" />
					<img src="/assets/svgs/variant29.svg" alt="" />
					<img src="/assets/svgs/variant30.svg" alt="" />
					<img src="/assets/svgs/employment.svg" alt="" />
					<img src="/assets/svgs/location.svg" alt="" />
					<img src="/assets/svgs/search.svg" alt="" />
				</div>
			</div>
		</div>
	);
};

export default UIComponents;
