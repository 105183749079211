import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './layouts';
import Home from './pages/Home';
import UIComponents from './pages/UIComponents';
import About from './pages/About';
import { Blog } from './pages/Blog/Blog';
import Career from './pages/Career/Career';
import { Vacancy } from './pages/Career/Vacancy';
import { OurWork } from './pages/OurWork/OurWork';
import Hire from './pages/Hire';
import BlogDetail from './pages/Blog/BlogDetail';

function App() {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/careers" element={<Career />} />
					<Route path="/vacancy" element={<Vacancy />} />
					<Route path="/work" element={<OurWork />} />
					<Route path="/hire" element={<Hire />} />
					<Route path="/blog/:slug" element={<BlogDetail />} />
				</Route>
				<Route path="/ui-components" element={<UIComponents />} />
			</Routes>
		</div>
	);
}

export default App;
