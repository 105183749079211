import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo } from "react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";

/*
 *
 */
const Header = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [open, setOpen] = useState(false);

  const dark = useMemo(() => {
    return !["work", "hire", "blog", "careers"].includes(
      location.pathname.split("/")[1]
    );
  }, [location]);
  const parentVariants = {
    hidden: {
      height: "0vh",
      opacity: 0,
    },
    visible: {
      height: "100vh",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "tween",
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
    exit: {
      height: "0vh",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
        when: "afterChildren",

        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };
  const childvariants = {
    hidden: {
      y: 50,
      opacity: 0,
    },

    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };
  return (
    <header
      className={`
        ${
          dark ? "bg-neutral-900   box-border  text-shades-0" : "bg-shades-0 "
        } z-20 w-screen
        fixed left-0 top-0 
        md:static 
        
     `}
    >
      <nav className=" py-2 mx-auto pl-4 pr-5 flex items-center justify-between">
        <div>
          <img
            src={
              dark
                ? "/assets/images/ideabreedLogoWhite.png"
                : "/assets/images/ideabreedLogo.png"
            }
            alt=""
          />
        </div>
        <section className="hidden md:block">
          <ul className="flex items-center space-x-16">
            <li>
              <NavLink to="/" end>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" end>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" end>
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink to="/careers" end>
                Careers
              </NavLink>
            </li>
            <li>
              <NavLink to="/work" end>
                Work
              </NavLink>
            </li>
            <li>
              <NavLink to="/hire" end>
                <button className="bg-transparent cursor-pointer hover:bg-primary-900 hover:text-shades-0 border-2 border-primary-900 rounded-[30px] px-[32px] py-[12px] text-l2 text-primary-900 uppercase">
                  Hire Us
                </button>
              </NavLink>
            </li>
          </ul>
        </section>
        <div
          className="cursor-pointer justify-self-end  md:hidden "
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {!open ? (
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              className={`${dark ? "fill-primary-900" : "fill-neutral-700"} `}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.1875 29C5.84444 29 5.56076 28.8878 5.33646 28.6635C5.11215 28.4392 5 28.1556 5 27.8125C5 27.4694 5.11215 27.1858 5.33646 26.9615C5.56076 26.7372 5.84444 26.625 6.1875 26.625H32.3125C32.6556 26.625 32.9392 26.7372 33.1635 26.9615C33.3878 27.1858 33.5 27.4694 33.5 27.8125C33.5 28.1556 33.3878 28.4392 33.1635 28.6635C32.9392 28.8878 32.6556 29 32.3125 29H6.1875ZM6.1875 20.6875C5.84444 20.6875 5.56076 20.5753 5.33646 20.351C5.11215 20.1267 5 19.8431 5 19.5C5 19.1569 5.11215 18.8733 5.33646 18.649C5.56076 18.4247 5.84444 18.3125 6.1875 18.3125H32.3125C32.6556 18.3125 32.9392 18.4247 33.1635 18.649C33.3878 18.8733 33.5 19.1569 33.5 19.5C33.5 19.8431 33.3878 20.1267 33.1635 20.351C32.9392 20.5753 32.6556 20.6875 32.3125 20.6875H6.1875ZM6.1875 12.375C5.84444 12.375 5.56076 12.2628 5.33646 12.0385C5.11215 11.8142 5 11.5306 5 11.1875C5 10.8444 5.11215 10.5608 5.33646 10.3365C5.56076 10.1122 5.84444 10 6.1875 10H32.3125C32.6556 10 32.9392 10.1122 33.1635 10.3365C33.3878 10.5608 33.5 10.8444 33.5 11.1875C33.5 11.5306 33.3878 11.8142 33.1635 12.0385C32.9392 12.2628 32.6556 12.375 32.3125 12.375H6.1875Z"
                fill="current"
              />
            </svg>
          ) : (
            <motion.svg
              width="30"
              height="30"
              viewBox="0 0 32 31"
              fill="none"
              className={`${
                dark ? "fill-primary-900" : "fill-neutral-700"
              } h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{
                  pathLength: 1,
                  transition: { duration: 1 },
                }}
                d="M15.9999 17.95L3.7499 30.2C3.3999 30.55 2.99157 30.725 2.5249 30.725C2.05824 30.725 1.6499 30.55 1.2999 30.2C0.949902 29.85 0.774902 29.4417 0.774902 28.975C0.774902 28.5084 0.949902 28.1 1.2999 27.75L13.5499 15.5L1.2999 3.25002C0.949902 2.90002 0.774902 2.49169 0.774902 2.02502C0.774902 1.55836 0.949902 1.15002 1.2999 0.800024C1.6499 0.450024 2.05824 0.275024 2.5249 0.275024C2.99157 0.275024 3.3999 0.450024 3.7499 0.800024L15.9999 13.05L28.2499 0.800024C28.5999 0.450024 29.0082 0.275024 29.4749 0.275024C29.9416 0.275024 30.3499 0.450024 30.6999 0.800024C31.0499 1.15002 31.2249 1.55836 31.2249 2.02502C31.2249 2.49169 31.0499 2.90002 30.6999 3.25002L18.4499 15.5L30.6999 27.75C31.0499 28.1 31.2249 28.5084 31.2249 28.975C31.2249 29.4417 31.0499 29.85 30.6999 30.2C30.3499 30.55 29.9416 30.725 29.4749 30.725C29.0082 30.725 28.5999 30.55 28.2499 30.2L15.9999 17.95Z"
                fill="current"
              />
            </motion.svg>
          )}
        </div>
      </nav>
      <AnimatePresence>
        {open && isMobile && (
          <motion.ul
            initial={"hidden"}
            animate={"visible"}
            exit={"exit"}
            variants={parentVariants}
            className={`${
              dark ? "bg-neutral-800" : " "
            }z-20  w-screen h flex flex-col items-center  justify-center space-y-10 `}
          >
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/" end>
                Home
              </NavLink>
            </motion.li>
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/about" end>
                About
              </NavLink>
            </motion.li>
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/blog" end>
                Blog
              </NavLink>
            </motion.li>
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/careers" end>
                Careers
              </NavLink>
            </motion.li>
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/work" end>
                Work
              </NavLink>
            </motion.li>
            <motion.li
              variants={childvariants}
              onClick={() => {
                setOpen(false);
              }}
            >
              <NavLink to="/hire" end>
                <button
                  className="bg-transparent cursor-pointer hover:bg-primary-900 hover:text-shades-0 border-2 border-primary-900 rounded-[30px] px-[32px] py-[12px] text-l2 text-primary-900 uppercase"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Hire Us
                </button>
              </NavLink>
            </motion.li>
          </motion.ul>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
