import { useState } from "react";
import styled from "styled-components";

//
import Tabs from "../../components/reusable/Tabs";

//
import { Header } from "../../components/reusable/Header";
import WorkWithUs from "../../components/@hire/WorkWithUs";
import Message from "../../components/@hire/Message";
import VisitOffice from "../../components/@hire/VisitOffice";

//
import { hireTabs, ideabreedAddress } from "../../constants/hire";

/*
 *
 */
export default function Hire() {
  const [active, setActive] = useState("workwithus");
  return (
    <div>
      <Header
        headerTextFirst={"Interested?"}
        headerTextSecond={"Let’s Talk"}
        subtitleText={"We’d like to discuss your awesome project"}
      />
      <StyledHire className="md:px-24 px-4 sm:px-10 md:mt-24">
        <Tabs
          tabs={hireTabs}
          className="mb:mb-16"
          active={active}
          setActive={setActive}
        />
        {active === "workwithus" ? (
          <WorkWithUs />
        ) : active === "message" ? (
          <Message />
        ) : (
          <VisitOffice idea={ideabreedAddress} />
        )}
      </StyledHire>
    </div>
  );
}

const StyledHire = styled.div``;
