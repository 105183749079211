import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const JobCards = ({ career, ...rest }) => {
  return (
    <motion.div
      {...rest}
      style={{ border: "0.5px solid #E6E6E6" }}
      className={`w-full  flex items-center shadow-jobcards justify-between md:pl-10 box-border shadow-md md:py-5 md:pr-6 p-4 sm:px-4 sm:py-5 border-1/2 `}
    >
      <div className="sm:flex sm:justify-between sm:items-center basis-1/2">
        <p className="md:text-l3 font-raleway text-ml2 leading-l20 text-neutral-800 sm:text-tl3 sm:leading-tl140 sm:tracking-[0.02em]">
          {career.title}
        </p>
        <p className="font-raleway mt-1 md:mt-0 text-ml4  leading-l140 text-neutral-600 md:text-l2 sm:text-tl14 sm:leading-tl140 sm:tracking-[0.02em] md:tracking-normal">
          {career.location}
        </p>
      </div>
      <Link
        className="flex justify-start items-center space-x-4"
        to={"/vacancy"}
      >
        <p className="md:text-l2 font-raleway text-primary-700 uppercase text-ml4 leading-l140 sm:text-tl14 sm:leading-tl140 sm:tracking-[0.02em] md:tracking-normal ">
          Apply
        </p>
        <button className="text-primary-300 hover:border hover:border-primary-700 hover:border-dashed p-2 md:pl-5 md:pr-4 md:py-4 hover:rounded-full h-10 w-10 box-border md:h-16 md:w-16">
          <svg
            width="24"
            height="24"
            viewBox="0 0 38 38"
            className="fill-primary-300 "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8719 32.6519L14 30.7466L25.4651 19.2815L14 7.8164L15.8719 5.91112L29.2422 19.2815L15.8719 32.6519Z"
              fill="current"
            />
          </svg>
        </button>
      </Link>
    </motion.div>
  );
};

export default JobCards;
