import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function HeroHire() {
  const navigate = useNavigate();
  return (
    <StyledHireUs className="py-[150px] hidden md:block col-span-3 overflow-hidden p-7 group cursor-pointer relative">
      <h3 className="text-h1 text-center text-neutral-700 relative  group-hover:text-shades-0 duration-200 transition  bg-transparent ">
        {" "}
        Have a Project in Mind?
      </h3>
      <p className="text-p1 text-neutral-600 text-center relative  group-hover:text-shades-0 duration-200 transition  bg-transparent ">
        Get in touch with us today
      </p>
      <span className="mySpan"></span>

      <div
        className="flex justify-center mt-12 cursor-pointer"
        onClick={() => navigate("/careers")}
      >
        <button className="join_btn border-2 h-[82px] w-[82px] flex justify-center items-center uppercase  rounded-full  text-l2 font-raleway    group-hover:text-shades-0 text-neutral-600 transition duration-200  relative">
          hire
          <br />
          us
        </button>
      </div>
    </StyledHireUs>
  );
}

const StyledHireUs = styled.div`
  &:hover > .mySpan {
    transform: scale(2000);
  }
  .join_btn::after,
  .join_btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    outline: 1px dashed white;
    outline-offest: 0;
    opacity: 0;

    transition: linear 200ms;
  }
  .mySpan {
    height: 1px;
    width: 1px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: #37a027;
    transition: linear 300ms;
  }

  .join_btn:hover::after {
    outline-offset: 16px;
    opacity: 1;
  }
  .join_btn:hover::before {
    outline-offset: 32px;
    opacity: 1;
  }
`;
