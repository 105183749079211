import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//
import useMediaQuery from "../../hooks/useMediaQuery";

const Hero = () => {
  const isSmall = useMediaQuery("(max-width:1024px)");
  return (
    <StyledHero className="h-[calc(100vh_-_80px)]  mt-20 md:mt-0 bg-neutral-900 relative overflow-x-hidden">
      <div
        id="hero-content"
        className="md:w-2/3  w-full  py-44 px-4 sm:px-10  sm:pt-80 sm:pb-40 md:px-40 md:py-24 z-10"
      >
        <h1 className="md:text-h1 text-mh1 sm:text-th1 font-lora leading-mh120 md:leading-none mb-2 whitespace-nowrap text-shades-0 fade-out-right">
          Where Innovation meets
        </h1>
        <h1
          className="md:text-h1 sm:text-th1 text-mh1  leading-[1.2] text-primary-700 font-lora fade-out-right mb-8"
          style={{ animationDelay: "200ms" }}
        >
          Excellence
        </h1>
        <p1
          className="md:text-p1 text-mp1 leading-[1.8] sm:text-tp1 sm:mt-10 font-raleway text-shades-0 fade-out-right"
          style={{ animationDelay: "400ms" }}
        >
          At Idea Breed, we have passion for turning ideas into reality. We
          achieve by designing and building rock solid product.
        </p1>
        {!isSmall && (
          <Link to="/hire" className="block mt-8" end>
            <button
              className="bg-transparent  border-2 border-primary-900 rounded-[30px] px-[32px] py-[12px] text-l2 text-primary-900 uppercase fade-out-right w-max"
              style={{ animationDelay: "600ms" }}
            >
              <span className="grow-animation">Hire Us</span>
            </button>
          </Link>
        )}
      </div>
      <div id="hero-bg" className="absolute inset-0">
        <div id="hero-image"></div>
      </div>
    </StyledHero>
  );
};

export default Hero;

const StyledHero = styled.div`
  .fade-out-right {
    position: relative;
    opacity: 0;
    animation: fadeoutright 500ms linear forwards;
  }

  #hero-bg {
    display: flex;
    justify-content: end;
    #hero-image {
      position: relative;
      background-image: url("/assets/images/hero/variant.svg");
      width: 50%;
      right: -10%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      animation: animateHero 5000ms linear infinite forwards;
    }
  }
  @keyframes animateHero {
    0% {
      background-image: url("/assets/images/hero/variant4.svg");
      opacity: 0;
    }
    50% {
      background-image: url("/assets/images/hero/variant4.svg");
      opacity: 1;
    }
    75% {
      background-image: url("/assets/images/hero/variant2.svg");
      opacity: 1;
    }
    100% {
      background-image: url("/assets/images/hero/variant.svg");
      opacity: 1;
    }
  }
  @keyframes fadeoutright {
    0% {
      left: 40%;
      opacity: 0;
    }

    100% {
      left: 0;
      opacity: 1;
    }
  }
`;
