import styled from 'styled-components';
import { IndividualBlog } from '../../components/@blog/IndividualBlog';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
/**
 *
 */
export default function BlogDetail() {
	const { slug } = useParams();
	const location = useLocation();

	const [blogMeta, setBlogMeta] = useState(null);
	const [markdown, setMarkdown] = useState('');
	useEffect(() => {
		const getmd = () =>
			fetch('/' + process.env.PUBLIC_URL + `blogs/${slug}.md`).then(
				async (res) => {
					let text = await res.text();
					setMarkdown(text);
				}
			);
		const getmeta = () =>
			fetch('/' + process.env.PUBLIC_URL + 'blogs/metadata.json').then(
				async (res) => {
					const queries = new URLSearchParams(location.search);
					let metas = JSON.parse(await res.text())?.blogs;
					let meta = metas?.find((m) => m?.title === queries.get('title'));
					setBlogMeta(meta);
				}
			);
		try {
			getmd();
			getmeta();
		} catch (error) {}
	}, [slug, location]);
	console.log(blogMeta);

	return (
		<StyledBlogDetail>
			<div className="header">
				<ul className="text-l6 font-raleway gap-x-8 uppercase inline-flex text-neutral-600">
					{blogMeta?.category}
					<li className="list-disc">{blogMeta?.date}</li>
				</ul>
				<h2 className="text-h2 font-lora mt-1 text-neutral-900">
					{blogMeta?.title}
				</h2>
				<div className="mt-6 inline-flex items-center gap-3">
					<img
						src={blogMeta?.author_image || '/assets/images/avatar.jpg'}
						alt=""
						className="h-10 w-10 rounded-full object-cover "
						srcset=""
					/>
					<span className="text-l5 font-lora text-neutral-800">
						Jeny Wilson
					</span>
				</div>
				<img
					src={blogMeta?.banner_image}
					alt=""
					className="w-full h-96 object-cover mt-10"
					srcset=""
				/>
			</div>
			<div className="blog-preview">
				<ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
			</div>

			{/* <div className="mx-auto mt-16 w-2/3">
				<h3 className="text-lora text-h3 font-raleway uppercase">dbms</h3>
				<p className="text-p2 font-raleway indent-4 text-neutral-700 text-justify">
					Stands for “Database Management System”. Data has now become a key
					component of any organization. Database is a collection of related
					data. Data are the known facts that can be recorded and that have
					implict meaning. It can be text, images, audio, video and so on.
					Companies use their business data to analyze their economic health and
					create business strategies. Data present in customer reports, market
					surveys, client data and stakeholder information needs to be managed
					properly.
					<br />
					<br />
					This is where a Database Management System comes in. It is used for
					collecting, storing, processing and retrieving data.
					<br />
					<br /> DBMS = data + set of programs to access/manipulate data.
					<br /> DBMS is a collection of program that enables user to create and
					maintain a database. An RDMS is an advanced version of it.
				</p>
				<h3 className="text-lora text-h3 mt-8 font-raleway ">What is RDBMS?</h3>
				<p className="text-p2 font-raleway indent-4 text-neutral-700 text-justify">
					Stands for “Relational Database Management System”. An RDBMS is a DBMS
					designed specifically for relational databases. Therefore, RDBMSes are
					a subset of DBMSes. A relational databases refers to a database that
					stores data in a structured format, using rows and columns. This makes
					it easy to locate and access specific values within the database. It
					is “relational” because the values within each table are related to
					each other. Tables may also be related to other tables. The relational
					structure makes it possible to run queries across multiple tables at
					once. <br />
					<br />
					All modern database Management Systems like SQL, MS SQL Server, IBM
					DB2, ORACLE, MY-SQL and Microsoft Access are based on RDBMS. Examples
					of non-relational databases include Apache HBase, IBM Domino, and
					Oracle NoSQL Database. These type of databases are managed by other
					DMBS programs that support NoSQL, which do not fall into the RDBMS
					category.
				</p>
			</div> */}
			<div className="recent mt-40">
				<h2 className="font-raleway text-h2  text-neutral-800">
					Recent <span className="text-primary-900">Blogs</span>
				</h2>
				<div className="grid grid-cols-3 gap-x-10 mb-44">
					<div className="col-span-1">
						<IndividualBlog
							blogText=" Stands for Relational Database Management System. An RDBMS is a DBMS designed specifically for relational databases. Therefore,RDBMSes are a subset of DBMSes."
							blogImage="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=815&q=80"
							blogHeader="Rdbms"
							writtenBy="Arpan  Khanal"
							category="Consultant"
							publishDate="April 22, 2022"
						/>
					</div>
					<div className="col-span-1">
						<IndividualBlog
							blogText=" Stands for Relational Database Management System. An RDBMS is a DBMS designed specifically for relational databases. Therefore,RDBMSes are a subset of DBMSes."
							blogImage="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=815&q=80"
							blogHeader="Rdbms"
							writtenBy="Arpan  Khanal"
							category="Consultant"
							publishDate="April 22, 2022"
						/>
					</div>
				</div>
			</div>
		</StyledBlogDetail>
	);
}
const StyledBlogDetail = styled.div`
	padding-top: 5rem;
	padding-left: 6.5rem;
	padding-right: 6.5rem;
	.blog-preview {
		h4 {
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 2;
		}
		code {
			background-color: gray;
		}
		pre code {
			display: block;
			background-color: black;
			color: white;
			margin-block: 1rem;
		}

		hr {
			margin-block: 1rem;
		}
	}
`;
