import { Disclosure } from "@headlessui/react";
import { useState } from "react";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { MdFacebook } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Hero } from "../../components/@about/Hero";
import { HeroAttribute } from "../../components/@about/HeroAttribute";
import { HeroBtn } from "../../components/@about/HeroBtn";
import HeroJoin from "../../components/@about/HeroJoin";
import { Introduction } from "../../components/@about/Introduction.jsx";
const faqs = [
  {
    id: 1,
    question:
      "I want to develop an e-commerce website for my store, how do your charge?  ",
    answer:
      "Hello, good to know you want to move your store to the internet. Actually the cost depends on the depth you want to go, features you want to support. Please call us or post your info from our site.",
  },
  {
    id: 2,
    question: "Can we use Idea Signage on trail basis for certain time? ",
    answer: "",
  },
  {
    id: 3,
    question:
      "I want to develop an e-commerce website for my store, how do your charge?  ",
    answer:
      "Hello, good to know you want to move your store to the internet. Actually the cost depends on the depth you want to go, features you want to support. Please call us or post your info from our site.",
  },
  {
    id: 4,
    question:
      "I want to develop an e-commerce website for my store, how do your charge?  ",
    answer:
      "Hello, good to know you want to move your store to the internet. Actually the cost depends on the depth you want to go, features you want to support. Please call us or post your info from our site.",
  },
];

export default function About() {
  const [oPen, setOpen] = useState([]);

  const navigate = useNavigate();
  return (
    <div>
      <Hero />
      <Introduction />
      <HeroAttribute />
      <div className="md:mt-[340px] px-4 md:px-24 py-16 sm:px-20 sm:py-20">
        <h2 className="md:text-h2 text-mh1 sm:text-th2 sm:leading-th120 sm:tracking-[0.02em] md:tracking-normal leading-mh1 md:leading-none font-lora text-center">
          Our <span className="text-primary-900">Team</span>
        </h2>
        <div className="bg-shades-0">
          <div className="max-w-auto mx-auto py-10">
            <div className="md:grid md:grid-cols-5 md:gap-4">
              <div className="grid grid-cols-3 md:col-span-3 gap-4  content-start">
                <div className=" col-span-1 space-y-4 mt-8  md:mt-24">
                  <div className="relative cursor-pointer  w-full">
                    <img
                      src="/assets/images/nibeshadhikari.png"
                      alt=""
                      srcset=""
                      className="h-[130px] md:h-[248px] w-full grayscale object-cover object-top  hover:grayscale-0 transition duration-500 "
                    />
                    <div className=" absolute inset-0 opacity-0  hover:opacity-75 transition duration-75 text-shades-0 bg-shades-100 flex justify-center items-center  ">
                      <div>
                        <h3 className="text-mh3  text-center leading-mh120 font-lora md:text-h3  ">
                          Nibesh Adhikari
                        </h3>
                        <p className="md:text-l3 md:leading-l2 text-ml3 leading-mh120 font-raleway text-center">
                          Co-founder | CEO
                        </p>
                        <div className="flex justify-center space-x-2 mt-2">
                          <MdFacebook fontSize={"18"} className="hover:font-" />
                          <BsTwitter fontSize={"18"} />

                          <BsLinkedin fontSize={"18"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    src="/assets/images/manishshrestha.png"
                    alt=""
                    srcset=""
                    className="h-[150px] md:h-[248px] w-full  object-cover object-top  grayscale hover:grayscale-0 transition duration-500"
                  />
                </div>
                <div className=" col-span-1 space-y-4 ">
                  <img
                    src="/assets/images/ashishpoudel.png"
                    alt=""
                    srcset=""
                    className="h-[160px] w-full  md:h-[248px] object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  />
                  <img
                    src="/assets/images/pratikkarki.png"
                    alt=""
                    srcset=""
                    className=" h-[152px] md:h-[347px] w-full  object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  />
                </div>
                <div className=" col-span-1 space-y-4 mt-5  md:mt-[55px]">
                  <img
                    src="/assets/images/sishirpandey.png"
                    alt=""
                    srcset=""
                    className="h-[142px]  w-full md:h-[248px] object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  />
                  <img
                    src="/assets/images/sajandhakal.png"
                    alt=""
                    srcset=""
                    className="h-[150px] md:h-[292px] object-cover object-top  grayscale hover:grayscale-0 transition duration-500 w-full"
                  />
                </div>

                <HeroJoin />

                <div className="col-span-3  grid grid-cols-3 gap-x-4 md:h-[250px] overflow-hidden">
                  <img
                    src="/assets/images/nabinpokharel.jpg"
                    alt=""
                    srcset=""
                    className=" h-[150px]  md:h-full  object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  />
                  <img
                    src="/assets/images/renukashrestha.jpg"
                    alt=""
                    srcset=""
                    className="w-full h-[150px] md:h-full  object-cover object-top grayscale hover:grayscale-0 transition duration-500 "
                  />
                  <div className="relative cursor-pointer  w-full">
                    <img
                      src="/assets/images/sangya_lamsal.jpeg"
                      alt=""
                      srcset=""
                      className="h-[130px] md:h-[248px] w-full grayscale object-cover object-top  hover:grayscale-0 transition duration-500 "
                    />
                    <div className=" absolute inset-0 opacity-0  hover:opacity-75 transition duration-75 text-shades-0 bg-shades-100 flex justify-center items-center  ">
                      <div>
                        <h3 className="text-mh3  text-center leading-mh120 font-lora md:text-h3  ">
                          Sangya Lamsal
                        </h3>
                        <p className="md:text-l3 md:leading-l2 text-ml3 leading-mh120 font-raleway text-center">
                          Data entry operator & Customer support Executive
                        </p>
                        <div className="flex justify-center space-x-2 mt-2">
                          <MdFacebook fontSize={"18"} className="hover:font-" />
                          <BsTwitter fontSize={"18"} />

                          <BsLinkedin fontSize={"18"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <img
                    src="/assets/images/renukashrestha.jpg"
                    alt=""
                    srcset=""
                    className="w-full h-[150px] md:h-full  object-cover object-top grayscale hover:grayscale-0 transition duration-500 "
                  /> */}
                  {/* <img
                    src="https://images.unsplash.com/photo-1610862169846-80fb009ed591?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                    alt=""
                    srcset=""
                    className=" w-full h-[150px] md:h-full object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  /> */}
                </div>
              </div>
              <div className="grid grid-cols-3 mt-4  md:mt-16  md:block md:col-span-1 md:space-y-4 md:order-first gap-4">
                <img
                  src="/assets/images/milanmalla.png"
                  alt=""
                  srcset=""
                  className="h-[150px] w-full md:h-[300px] object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                />
                <img
                  src="/assets/images/sushilshrestha.png"
                  alt=""
                  srcset=""
                  className="w-full h-[150px] md:h-[300px] object-cover object-top grayscale hover:grayscale-0 transition duration-500 "
                />
                <img
                  src="/assets/images/kumaradhikari.jpg"
                  alt=""
                  srcset=""
                  className=" w-full h-[150px] md:h-[300px] object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                />
              </div>
              <div className="grid grid-cols-3 mt-4 md:mt-32 md:block md:col-span-1 md:order-last  md:space-y-4 gap-4">
                <img
                  src="/assets/images/vikalshrestha.png"
                  alt=""
                  srcset=""
                  className="w-full h-[150px] md:h-[248px] object-cover object-top  grayscale hover:grayscale-0 transition duration-500"
                />
                <img
                  src="/assets/images/arpankhanal.png"
                  alt=""
                  srcset=""
                  className="w-full h-[150px] md:h-[300px] object-cover object-center grayscale hover:grayscale-0 transition duration-500 "
                />
                <img
                  src="/assets/images/biratasubedi.png"
                  alt=""
                  srcset=""
                  className=" w-full h-[150px] md:h-[300px] object-cover object-top  grayscale hover:grayscale-0 transition duration-500"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-24 mt-20">
          <div className="grid grid-cols-5 gap-x-2">
            <div className="col-span-1 flex md:order-first flex-col space-y-2 pt-14">
              <div className="relative w-max group text  cursor-pointer">
                <img
                  src="/assets/images/nabinpokharel.jpg"
                  alt=""
                  className=" h-48 md:h-auto object-cover object-top grayscale hover:grayscale-0 transition duration-500 "
                  title="Nabin Pokharel"
                />

                <div class="absolute inset-0 transition ease-linear opacity-0 hover:bg-neutral-900 hover:opacity-100  duration-200">
                  <div class=" duration-1000 pl-14 h-full w-full flex items-center text-primary-50 group-hover:pl-10">
                    <div>
                      <h4 className="text-h4  mb-2 font-lora">
                        Nabin Pokharel
                      </h4>
                      <p className="text-l3  font-raleway"> React Developer</p>
                    </div>
                  </div>
                </div>
              </div>

              <img
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
                className="w-full"
                srcset=""
              />
              <img
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
                className="w-full"
                srcset=""
              />
            </div>
            <div className="col-span-3 grid grid-cols-3 gap-x-2">
              <div className=" col-span-1 space-y-2 pt-20 mb-2">
                <img
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="w-full h-full md:h-auto object-cover object-top grayscale hover:grayscale-0 transition duration-500 grayscale-1"
                  srcset=""
                />
              </div>
              <div className="col-span-1 flex flex-col  space-y-2 mb-2">
                <img
                  src="https://images.unsplash.com/photo-1480429370139-e0132c086e2a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1376&q=80"
                  alt=""
                  className="w-full h-full md:h-auto object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  srcset=""
                />
              </div>
              <div className="col-span-1 flex flex-col  space-y-2 pt-10 mb-2">
                <img
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="w-full h-full md:h-auto object-cover object-top grayscale hover:grayscale-0 transition duration-500"
                  srcset=""
                />
              </div>

              <HeroJoin />

              <div className=" col-span-1 space-y-2 pt-2">
                <img
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="w-full"
                  srcset=""
                />
              </div>
              <div className=" col-span-1 space-y-2 pt-2">
                <img
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="w-full"
                  srcset=""
                />
              </div>
              <div className=" col-span-1 space-y-2 pt-2">
                <img
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="w-full"
                  srcset=""
                />
              </div>
            </div>

            <div className=" col-span-1 flex flex-col   space-y-2 pt-24">
              <img
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
                className="w-full"
                srcset=""
              />
              <img
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
                className="w-full"
                srcset=""
              />
              <img
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
                className="w-full"
                srcset=""
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className="bg-gray-50 md:mt-[200px]">
        <div className="max-w-7xl mx-auto py-16 px-4  sm:px-10 sm:py-20 lg:px-8">
          <div className="max-w-3xl mx-auto ">
            <h2 className="md:text-h2 sm:text-th1 sm:leading-th140  text-center font-lora leading-mh1 text-mh1">
              FAQs
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-neutral-100">
              {faqs.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="pt-6 transition duration-500"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button
                          className="text-left w-full   flex justify-between items-start"
                          onClick={() => {
                            const index = oPen.indexOf(faq.id);
                            if (index > -1) {
                              const temp = [...oPen];
                              temp.splice(index, 1);
                              setOpen(temp);
                            } else {
                              setOpen((prev) => [...prev, faq.id]);
                            }
                          }}
                        >
                          <span className="md:text-h4 sm:text-th4 sm:leading-th140 sm:tracking-[0.02em] md:tracking-normal  text-neutral-800 text-mh3 leading-mh3 font-lora">
                            {faq.question}
                          </span>
                          <span className="ml-7 h-7 pt-2 flex items-center">
                            <HeroBtn
                              className={`${
                                oPen.includes(faq.id) ? "" : "open"
                              }`}
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>

                      <Disclosure.Panel as="dd" className="mt-2  pr-12">
                        <p className="md:text-p1 md:leading-l22 sm:text-tp3 sm:leading-tp140 sm:tracking-normal  font-raleway  tracking-mp4  leading-mp4 text-mp4  text-neutral-700">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <StyledHireUs className="py-[150px] hidden md:block col-span-3 overflow-hidden p-7 group cursor-pointer relative">
        <h3 className="text-h1 text-center text-neutral-700 relative  group-hover:text-shades-0 duration-200 transition  bg-transparent ">
          {" "}
          Have a Project in Mind?
        </h3>
        <p className="text-p1 text-neutral-600 text-center relative  group-hover:text-shades-0 duration-200 transition  bg-transparent ">
          Get in touch with us today
        </p>
        <span className="mySpan"></span>

        <div
          className="flex justify-center mt-12 cursor-pointer"
          onClick={() => navigate("/careers")}
        >
          <button className="join_btn border-2 h-[82px] w-[82px] flex justify-center items-center uppercase  rounded-full  text-l2 font-raleway    group-hover:text-shades-0 text-neutral-600 transition duration-200  relative">
            hire
            <br />
            us
          </button>
        </div>
      </StyledHireUs>
    </div>
  );
}

const StyledHireUs = styled.div`
  &:hover > .mySpan {
    transform: scale(2000);
  }
  .join_btn::after,
  .join_btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    outline: 1px dashed white;
    outline-offest: 0;
    opacity: 0;

    transition: linear 200ms;
  }
  .mySpan {
    height: 1px;
    width: 1px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: #37a027;
    transition: linear 300ms;
  }

  .join_btn:hover::after {
    outline-offset: 16px;
    opacity: 1;
  }
  .join_btn:hover::before {
    outline-offset: 32px;
    opacity: 1;
  }
`;
