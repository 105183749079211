import React from "react";
import { motion } from "framer-motion";

import { spanDecider } from "../../helper/DynamicGrid";
const HoverableData = ({ title, imgsrc, ind, ...rest }) => {
  return (
    <motion.div
      {...rest}
      className={`relative ${
        spanDecider(ind + 1) ? "col-span-2" : "col-span-1"
      }  h-[31rem] group overflow-hidden`}
    >
      <img
        src={imgsrc}
        alt=""
        className="w-full h-full object-cover group-hover:scale-125  transition duration-1000 ease-in-out"
      />
      <div className="absolute inset-x-0 top-0 p-6 sm:p-10  text-shades-0">
        <p className="uppercase text-l6 font-raleway sm:text-tl3 sm:leading-tl140 sm:tracking-[0.02em] md:tracking-normal">
          web app
        </p>
        <h4 className="text-h4 font-lora text-shades-0 capitalize mt-2 sm:text-th3 sm:leading-th140 sm:tracking-[0.02em]  md:tracking-normal">
          {title}
        </h4>
      </div>
      <div className=" absolute  left-0 bottom-0 p-6 sm:px-10 sm:py-8 bg-transparent">
        <div className="p-2 flex justify-center items-center bg-shades-0   h-8 w-8 sm:w-16 sm:h-16 rounded-full">
          <svg
            width="32"
            height="32"
            viewBox="0 0 45 38"
            fill="none"
            className="cursor-pointer fill-neutral-700 "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.4167 25.6042C24.2903 25.6042 25.8802 24.951 27.1865 23.6448C28.4927 22.3385 29.1458 20.7486 29.1458 18.875C29.1458 17.0014 28.4927 15.4115 27.1865 14.1052C25.8802 12.799 24.2903 12.1458 22.4167 12.1458C20.5431 12.1458 18.9531 12.799 17.6469 14.1052C16.3406 15.4115 15.6875 17.0014 15.6875 18.875C15.6875 20.7486 16.3406 22.3385 17.6469 23.6448C18.9531 24.951 20.5431 25.6042 22.4167 25.6042ZM22.4167 23.3083C21.1764 23.3083 20.1274 22.8795 19.2698 22.0219C18.4122 21.1642 17.9833 20.1153 17.9833 18.875C17.9833 17.6347 18.4122 16.5858 19.2698 15.7281C20.1274 14.8705 21.1764 14.4417 22.4167 14.4417C23.6569 14.4417 24.7059 14.8705 25.5635 15.7281C26.4212 16.5858 26.85 17.6347 26.85 18.875C26.85 20.1153 26.4212 21.1642 25.5635 22.0219C24.7059 22.8795 23.6569 23.3083 22.4167 23.3083ZM22.4167 30.75C18.5639 30.75 15.0806 29.6549 11.9667 27.4646C8.85278 25.2743 6.53056 22.4111 5 18.875C6.53056 15.3389 8.85278 12.4757 11.9667 10.2854C15.0806 8.09514 18.5639 7 22.4167 7C26.2694 7 29.7528 8.09514 32.8667 10.2854C35.9806 12.4757 38.3028 15.3389 39.8333 18.875C38.3028 22.4111 35.9806 25.2743 32.8667 27.4646C29.7528 29.6549 26.2694 30.75 22.4167 30.75ZM22.4167 28.375C25.6097 28.375 28.5455 27.5108 31.224 25.7823C33.9024 24.0538 35.941 21.7514 37.3396 18.875C35.941 15.9986 33.9024 13.6962 31.224 11.9677C28.5455 10.2392 25.6097 9.375 22.4167 9.375C19.2236 9.375 16.2878 10.2392 13.6094 11.9677C10.9309 13.6962 8.87917 15.9986 7.45417 18.875C8.87917 21.7514 10.9309 24.0538 13.6094 25.7823C16.2878 27.5108 19.2236 28.375 22.4167 28.375Z"
              fill="current"
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default HoverableData;
