import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import IBINFOIMAGE from "./info.jpg";
import { motion } from "framer-motion";

function InfoModal() {
  const [showModal, setShowModal] = React.useState(true);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showModal]);

  if (!showModal) return null;

  return (
    <>
      <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none h-screen overflow-hidden">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="relative w-auto my-12 mx-auto max-w-3xl"
        >
          <span className="group">
            <IoIosCloseCircle
              fontSize={34}
              onClick={() => setShowModal(false)}
              className="absolute z-10 right-[-15px] top-[-15px] bg-[#fff] rounded-full group-hover:scale-110 transition-transform cursor-pointer"
            />
          </span>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-[#fff] outline-none focus:outline-none  h-[90vh] overflow-y-auto">
            <div className="relative py-2 px-6 flex-auto overflow-auto">
              <img
                src={IBINFOIMAGE}
                alt="ideabreed website update info"
                className="w-full"
              />
            </div>
          </div>
        </motion.div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-[#fff]"></div>
    </>
  );
}

export default InfoModal;
