import styled from "styled-components";
import FormGroup from "../FormGroup";

/**
 *
 *
 */
export default function Message() {
  return (
    <StyledMessage className="md:pt-28 pt-10 sm:pt-14 md:w-1/2 w-5/6  ">
      <FormGroup.Text id="name" name="name" label={"Your Name"} type="text" />

      <FormGroup.Text
        id="email"
        name="email"
        label={"Email Address"}
        className="md:mt-16 mt-10 sm:mt-14"
      />

      <FormGroup.TextArea
        label={"Your Message"}
        id="comment "
        name="comment"
        minRows={4}
        className="md:mt-16 mt-10 sm:mt-14"
      />

      <button className="md:mt-16 mb-16 mt-14 border-2 text-ml3 active:border-2 font-raleway leading-l140 hover:bg-primary-900 hover hover:shadow-hirebtn  hover:text-shades-0  active:shadow-md focus:shadow-md border-primary-900 rounded-[30px] px-8 py-3 md:text-l2 text-primary-900 uppercase fade-out-right w-max">
        <span className="grow-animation uppercase">Send Message </span>
      </button>
    </StyledMessage>
  );
}
const StyledMessage = styled.div`
  animation: message 1s linear forwards;
  @keyframes message {
    0% {
      margin-top: 10rem;
    }
    100% {
      opacity: 1;
      margin-top: 0rem;
    }
  }
`;
