const Tabs = ({ tabs, active, setActive, className }) => {
  return (
    <div className={` `}>
      <div className="sm:block">
        <div className="">
          <ul
            className="flex py-4 md:py-0 space-x-8 sm:pl- sm:space-x-20 md:space-x-10 "
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <li
                key={tab.name}
                onClick={() => {
                  setActive(tab.code);
                }}
                className={`${
                  tab.code === active
                    ? "border-x-primary-900   text-neutral-900"
                    : "border-transparent   text-neutral-500  hover:text-neutral-600 hover:border-gray-300"
                } 

          whitespace-nowrap pt-1 transition duration-200 md:pb-3 pb-2 cursor-pointer list-none font-raleway md:text-l5 sm:text-tl3 sm:leading-tl160 text-ml2 leading-l20 border-b-2  `}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
