import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useMediaQuery from "../../hooks/useMediaQuery";

export const Header = ({
  headerTextFirst,
  headerTextSecond,
  subtitleText,
  page,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const isMobile = useMediaQuery("(max-width:430px)");
  const gtm = useMediaQuery("(min-width:430px");
  const ltl = useMediaQuery("(max-width:768px)");
  const isTab = gtm && ltl;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <StyledHeader
      className={`${
        !isMobile && !isTab
          ? page === "careers"
            ? "md:h-[24.125rem]"
            : "md:h-[18.75rem]"
          : ""
      }bg-shades-0 mt-20 md:mt-0  sm:px-10 sm:py-32 px-4 md:px-[6.25rem] md:pt-[4.75rem] py-[71px]`}
      page={page}
    >
      <h1 className="md:text-h1 text-neutral-800 sm:text-th1 sm:leading-th120  blog_header font-lora text-mh1 leading-mh1  md:leading-none ">
        {headerTextFirst}
        <span
          className={`md:text-h1 text-primary-900 capitalize ${
            isHovering ? "twinkle " : " "
          }`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {headerTextSecond}{" "}
        </span>
      </h1>
      <p className="md:mt-10 mt-5 sm:mt-10  sm:text-tp1 sm:leading-tp180 sm:tracking-[0.02em] md:tracking-normal text-mp1 leading-l180 md:leading-none transition blog_subtitle text-neutral-800 md:text-p1 font-raleway">
        {subtitleText}
      </p>
      {page === "careers" && (
        <Link to="/hire" className="block mt-8" end>
          <button className="bg-transparent  join_button box-border whitespace-nowrap   overflow-hidden border-primary-900  text-shades-0 hover:text-shades-0  rounded-full  py-3 text-l2  uppercase fade-out-right w-max">
            <span className="">see openings</span>
          </button>
        </Link>
      )}
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  
 
  .twinkle {
    animation: twinkleanimation 1s linear infinite;
  }
  .join_button {  
    animation: buttonanimation 1s linear 500ms forwards;
  }

  .blog_header {
    opacity:1;
    padding-left:0px;
    animation: blogheader 500ms linear  forwards;
  }

  .blog_subtitle {
    padding-left: 0px;;
    opacity: 1;
    animation: blogsubtitle 600ms linear forwards;
  }

  @keyframes blogheader {
    0% {      
        padding-left: 87px;
        opacity: 0;
      }
    }
    100% {      
        padding-left: 0px;
        opacity: 1;

      }
  }
  @keyframes blogsubtitle {
    0% {      
        padding-left: 192px;
        opacity: 0;
      }
    }
    100% {      
        padding-left: 0px;
        opacity: 0.8;
      }
  }
  @keyframes twinkleanimation {
    0% {      
           background: -webkit-linear-gradient(150deg, #37A027,cyan );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    
    }
    100% {     
      background: -webkit-linear-gradient(150deg,cyan,#37A027 );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;      
  }
}
@keyframes buttonanimation {
  0%{
    margin-left:192px;    
    opacity:0.5;
    border:2px solid #37A027;
    color:transparent;    
    width:3.25rem;    
    overflow:hidden;  
    
  }
  25%{
    margin-left:0;   
    opacity:0.5;
    color:transparent;
    border:2px solid #37A027;
    width:3.25rem;
    overflow:hidden;
  

  }
  50%{
    opacity:1;
    width:6rem;
    border:2px solid #37A027;
    color:#37A027; 
    overflow:hidden;
    padding-left:32px;
    padding-right:32px;
   

  }
  75%  {
  width:9rem;
  color:#37A027;
  padding-left:32px;
  padding-right:32px;
  border:2px solid #37A027;
    }



  100% {  
    border:2px solid #37A027;
    color:#37A027;
    padding-left:32px;
    padding-right:32px;
    width:full;
   
  }
   
}

`;
