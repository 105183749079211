import styled from "styled-components";

//
import { motion } from "framer-motion";

//
import JobCards from "../../components/@careers/JobCards";

//
import Perks from "../../components/@careers/Perks";

//
import { Header } from "../../components/reusable/Header";

//
import { perks } from "../../constants/careers";

//
import useMediaQuery from "../../hooks/useMediaQuery";

//jobs
const jobs = [
  {
    title: "Sr Software Enginner",
    location: "Remote",
  },
  {
    title: "Assocaiate Software Enginner",
    location: "Office",
  },
  {
    title: "UI/UX Designer",
    location: "Remote",
  },
];

/**
 *
 */
export default function Career() {
  const isMobile = useMediaQuery("(max-width:640px)");
  const gtm = useMediaQuery("(min-width:640px)");
  const ltl = useMediaQuery("(max-width:1024px)");
  const isTab = gtm && ltl;

  const jobCardVariants =
    !isMobile & !isTab
      ? {
          initial: {
            marginTop: 90,
          },
          final: {
            marginTop: 50,
          },
        }
      : {
          initial: {
            marginTop: 40,
          },
          final: {
            marginTop: 24,
          },
        };

  return (
    <div>
      <Header
        headerTextFirst={"Join us at "}
        headerTextSecond={"Idea Breed"}
        subtitleText={
          "Be a part of our family. We are an awesome team to join - people say 😀"
        }
        page={"careers"}
      />
      <StyledJoinUs className="flex gap-1 sm:gap-x-4  py-16 md:py-40 md:h-[52.375rem]  h-full sm:py-20 ">
        <div className="basis-1/2">
          <div className="h-24 sm:h-48 md:h-auto  bg-neutral-900 p-5 sm:p-[46px] text-ml2 leading-l20 text-shades-0 md:py-20">
            <p className="md:text-l1 text-ml font-raleway sm:text-tl1 sm:leading-tl140 sm:tracking-[0.02em] md:tracking-normal">
              Life at
            </p>
            <h2 className="md:text-h2 font-raleway text-mh2 leading-mh140 md:mt-2 sm:mt-1 sm:text-th2  sm:leading-th120 sm:tracking-[0.02em] text-primary-700">
              Idea Breed
            </h2>
          </div>
          <div className="flex gap-1 sm:gap-x-4 mt-1 sm:mt-4 h-24  md:h-[348px] sm:h-[200px]">
            <div className="basis-1/2 md:h-full">
              <img
                src="assets/images/lunch.jfif"
                alt=""
                className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition "
              />
            </div>
            <div className="basis-1/2 md:h-full">
              <img
                src="assets/images/working.jfif"
                alt=""
                className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition"
              />
            </div>
          </div>
        </div>
        <div className="basis-1/2 md:h-[317px] ">
          <div className="flex h-32 gap-x-1 sm:gap-x-4 md:h-full  sm:h-[249px]">
            <div className="basis-1/2 md:h-full">
              {" "}
              <img
                src="assets/images/birthday.jfif"
                alt=""
                className="h-full w-full object-cover grayscale hover:grayscale-0 duration-500 transition"
              />
            </div>
            <div className="basis-1/2 ">
              {" "}
              <img
                src="assets/images/treking.jfif"
                alt=""
                className="h-full w-full object-cover grayscale hover:grayscale-0 duration-500 transition"
              />
            </div>
          </div>
          <div className="h-16 mt-1 sm:mt-4 md:h-[280px] sm:h-[150px]">
            <img
              src="assets/images/wholeimage.jfif"
              alt=""
              srcset=""
              className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition object-left"
            />
          </div>
        </div>

        {/* <motion.div
          variants={leftvariants}
          initial={"initial"}
          whileInView={"final"}
          className=" w-2/5 h-full   flex-col"
        >
          <div className="h-1/2  bg-neutral-900  text-shades-0   py-20">
            <p className="text-l1 font-raleway">Life at</p>
            <h2 className="text-h2 font-raleway text-primary-700">
              Idea Breed
            </h2>
          </div>
          <div className=" grid  grid-cols-2  h-1/2 gap-2 pt-2">
            <div className=" col-span-1">
              <img
                alt=""
                src="assets/images/lunch.jfif"
                className="h-full w-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer hover:grayscale-0  grayscale duration-500 transition"
              />
            </div>
            <div className=" col-span-1">
              <img
                src="assets/images/working.jfif"
                alt=""
                className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer hover:grayscale-0 transition  duration-500 grayscale "
              />
            </div>
          </div>
        </motion.div>

        <div className="  w-1/3  h-full ">
          <div className="flex flex-row gap-2 pb-2  h-3/5">
            <div className=" w-1/2  h-full">
              {" "}
              <img
                alt=""
                src="assets/images/birthday.jfif"
                className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer   grayscale hover:grayscale-0 transition  duration-500"
                srcset=""
              />
            </div>
            <div className=" w-1/2">
              <img
                alt=""
                src="assets/images/treking.jfif"
                className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer hover:grayscale-0 transition  duration-500 grayscale "
                srcset=""
              />
            </div>
          </div>
          <motion.div
            variants={lefttvariants2}
            initial={"initial"}
            whileInView={"final"}
            className="w-full  h-2/5"
          >
            <img
              alt=""
              src="assets/images/wholeimage.jfif"
              className="h-full w-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer hover:grayscale-0 transition  duration-500 grayscale "
              srcset=""
            />
          </motion.div>
        </div>

        <div className=" w-1/4 h-full gap-2">
          <img
            alt=""
            src="assets/images/working.jfif"
            className="w-full h-full object-cover grayscale hover:grayscale-0 duration-500 transition cursor-pointer hover:grayscale-0 transition  duration-500 grayscale "
            srcset=""
          />
        </div> */}
      </StyledJoinUs>
      <StyledSeeOpenings className="px-4 md:px-24 py-16 md:py-40 md:h-[69.375rem] md:pb-40 sm:px-20 sm:py-40">
        <motion.h2 className=" md:text-h2 text-mh1 font-lora sm:text-th2 sm:leading-th120 sm:tracking-[0.02em] md:tracking-normal">
          See our <span className="text-primary-900">Job</span> Openings
        </motion.h2>
        <div>
          {jobs.map((nd, idx) => {
            return (
              <JobCards
                key={idx}
                initial={"initial"}
                whileInView={"final"}
                variants={jobCardVariants}
                viewport={{ once: true }}
                career={nd}
                transition={{ type: "tweeen", duration: 0.3 }}
              />
            );
          })}
        </div>
      </StyledSeeOpenings>
      <StyledPerks className="md:px-24 md:pt-40 py-16 px-4 sm:px-10 sm:py-20">
        <h2 className="font-lora md:text-h2 text-primary-900 text-mh1 leading-mh120 whitespace-nowrap sm:text-th2 sm:tracking-[0.02em]">
          Perks <span className="text-neutral-800">we provide</span>
        </h2>
        <p className="font-raleway md:text-p1 text-neutral-600 text-mp4 leading-p160 mt-2 md:mt-0  sm:text-tp2  sm:tracking-[0.02em] md:tracking-normal">
          We always care our members. After all, we are a single family.
        </p>
        <div className="md:mt-24 md:mb-32 sm:mt-12 grid md:grid-cols-3 md:gap-10 mt-8 grid-cols-2 gap-x-4 gap-y-16 sm:gap-x-5 sm:gap-y-14">
          {perks.map((pd, ind) => {
            return (
              <Perks key={ind} title={pd.title} list={pd.list} svg={pd.svg} />
            );
          })}
        </div>
      </StyledPerks>
    </div>
  );
}
const StyledJoinUs = styled.div``;
const StyledSeeOpenings = styled(motion.div)`
  overflow: hidden;
  box-sizing: border-box;
`;
const StyledPerks = styled.div`
  padding-top: 10rem;
`;
