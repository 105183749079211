import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const IndividualBlog = ({
  blogText,
  blogImage,
  blogHeader,
  writtenBy,
  category,
  author,
  publishDate,
  authorImage,
  slug,
  ...rest
}) => {
  return (
    <Link to={slug + `?title=${blogHeader}`}>
      <motion.div
        className={`col-span-1`}
        {...rest}
        // const gtm = useMediaQuery("(min-width:430px");
      >
        <div className="relative">
          <img
            src={blogImage}
            alt=""
            className="w-full object-cover relative"
            srcset=""
          />
          <div className="absolute inset-x-0 bottom-0">
            <div className="p-4 flex justify-start items-center space-x-2 md:space-x-3">
              <img
                src={authorImage || "/assets/images/avatar.jpg"}
                alt=""
                className="md:h-8 md:w-8 h-7 w-7 object-cover rounded-full"
                srcset=""
              />
              <p className="text-shades-0 md:text-l5 sm:text-tl14 sm:leading-tl140 md:leading-none text-ml1 leading-ml1 font-raleway">
                {writtenBy}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-3 sm:mt-4">
            {/* <p className="text-l6 font-raleway text-neutral-600 inline-flex  space-x-1 items-center">
            <span className="flex-0 uppercase">{category}</span>
            <span className="h-1 w-1 bg-neutral-600 "></span>
            <span className="">{publishDate}</span>
          </p> */}
            <ul>
              <dl className="font-raleway sm:text-tl5  md:text-l6 text-neutral-600  text-ml4 leading-l140  flex   uppercase">
                <dt className="md:mr-3 mr-2">{category}</dt>
                <dd className="md:ml-3 ml-2">
                  <li className="list-disc m-0 p-0">{publishDate}</li>
                </dd>
              </dl>
            </ul>
            <div className="md:mt-2 sm:mt-1 mt-1">
              <h4 className="text-neutral-900 sm:text-th4 sm:leading-th140 sm:tracking-[0.02em] md:text-h4 text-mh2 leading-mh180 md:leading-none font-lora">
                {blogHeader}
              </h4>
              <p className="mt-3 sm:text-tp4 sm:leading-tp180 font-raleway text-neutral-600 md:text-blog_description text-mp5 leading-p160">
                {blogText}
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};
