import { useState } from "react";
import styled from "styled-components";

import HoverableData from "../../components/@ourwork/HoverableData";

//
import { Header } from "../../components/reusable/Header";
import Tabs from "../../components/reusable/Tabs";

//
import { tabs, webApp } from "../../constants/ourwork";

export const OurWork = () => {
  const [active, setActive] = useState("webapp");
  const childvariants = {
    hidden: { opacity: 0 },
    visible: (ind) => ({
      opacity: 1,
      transition: { delay: 0.1 * Math.floor(ind / 3) },
    }),
  };
  return (
    <StyledOurWork>
      <Header
        headerTextFirst={"our "}
        headerTextSecond="work"
        subtitleText={
          "Since 2014, we have completed 45 projects. 46th could be yours."
        }
      />
      <StyledOurWorkBody>
        <Tabs
          tabs={tabs}
          className="md:mb-16 md:px-16 px-4 "
          active={active}
          setActive={setActive}
        />
        {}
        <div className="grid  md:grid-cols-4 sm:grid-col-2 gap-2 ">
          {webApp.map((cd, idx) => (
            <HoverableData
              title={cd.title}
              key={idx}
              imgsrc={cd.image}
              ind={idx}
              initial={"hidden"}
              whileInView={"visible"}
              variants={childvariants}
              custom={idx}
              viewport={{ once: true }}
            />
          ))}
        </div>
      </StyledOurWorkBody>
    </StyledOurWork>
  );
};
const StyledOurWork = styled.div`
  overflow-x: hidden;
`;
const StyledOurWorkBody = styled.div``;
