import React from "react";

const Perks = ({ title, list, svg }) => {
  return (
    <>
      <div>
        <div className=" inline-flex border-primary-900  box-border w-16 h-16 sm:h-[86px] sm:w-[86px]   justify-center items-center border-2   border-dashed  rounded-full">
          {svg}
        </div>
        <div className="md:mt-6 mt-3">
          <h4 className="md:text-h4 font-lora text-mh4 text-neutral-900 sm:text-th4 sm:leading-th140 sm:tracking-[0.02em] md:tracking-normal">
            {title}
          </h4>
          <ul className=" font-raleway md:text-p2  list-disc px-6  text-mp3 leading-p180 text-neutral-700 sm:text-tp3 sm:leading-th180 ">
            {list.map((ld, i) => (
              <li key={i} className="">
                {ld}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Perks;
