/**
 *
 */
export const hireTabs = [
  { name: "Work with us", code: "workwithus" },
  { name: "Message", code: "message" },
  { name: "Visit Office", code: "visitoffice" },
];

export const ideabreedAddress = {
  tol: "Pragati Nagar Road",
  district: "Kathmandu ",
  country: "Nepal",
  phone: "01-4785170",
  email: "ideabreed@gmail.com, info@ideabreed.net",
  place: "New Baneshwor",
  business_hour: "5 days a week, Mon - Fri",
  working_hour: "9 AM - 6 PM",
};
