import styled from "styled-components";

export const HeroBtn = ({ className }) => {
  return (
    <StyledHeroBtn className="  border-dashed border-neutral-700 box-border rounded-full border px-2 py-4">
      <div className={`${className} toggle`}></div>
    </StyledHeroBtn>
  );
};
const StyledHeroBtn = styled.div`
  .toggle {
    height: 2px;
    width: 16px;
    background-color: #525252;
    position: relative;
    &::after {
      content: " ";
      height: 2px;
      width: 16px;
      background-color: #525252;
      position: absolute;
      transition: linear 200ms;
    }
    &.open::after {
      transform: rotate(90deg);
    }
  }
`;
