import styled from "styled-components";

export const Hero = () => {
  return (
    <StyledHero className="-z-1 mt-20 md:mt-0 py-[70px] sm:py-32 md:py-0 md:h-96 md:flex md:pl-[100px] bg-neutral-900 md:items-center md:relative">
      <h1 className=" md:text-h1  md:mt-4 px-4 text-mh1 leading-mh1 font-lora hero_content  text-white sm:mt-5 sm:leading-th120 lg:mt-6 sm:text-th1 lg:text-5xl xl:text-6xl">
        <span className="md:block text-shades-0 hero_content md:mb-4 font-lora ">
          About
        </span>{" "}
        <span
          className="text-primary-900 hero_content   md:mt-4 sm:mt-2"
          style={{ animationDelay: "100ms" }}
        >
          Ideabreed
        </span>
      </h1>
    </StyledHero>
  );
};
const StyledHero = styled.div`
  .hero_content {
    position: relative;
    animation: fadeoutright 1s ease-in-out forwards;
  }
  @keyframes fadeoutright {
    0% {
      padding-left: 5rem;
      opacity: 0;
    }

    100% {
      padding-left: 0;
      opacity: 1;
    }
  }
`;
