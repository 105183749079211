import { useState } from "react";
//
import styled from "styled-components";

//
import { IndividualBlog } from "../../components/@blog/IndividualBlog";

//
import { useEffect } from "react";
import { Header } from "../../components/reusable/Header";
import { slugify } from "../../helper/methods";

//
const blogVarians = {
  initial: { opacity: 0, y: 100 },
  final: (ind) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * ind,
      type: "spring",
      stiffness: 120,
    },
  }),
};

/**
 *
 */
export const Blog = () => {
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    const getMetadata = () =>
      fetch("/" + process.env.PUBLIC_URL + "blogs/metadata.json").then(
        async (res) => {
          let json = JSON.parse(await res.text());
          setblogs(json?.blogs);
        }
      );
    try {
      getMetadata();
    } catch (error) {}
  }, []);
  console.log(blogs);
  return (
    <div>
      <Header
        headerTextFirst={"Idea's "}
        headerTextSecond="Blog"
        subtitleText={"Explore and read blog by our members"}
      />

      <StyledBlog className="mx-auto max-w-7xl sm:px-10 md:px-24 px-4 py-10">
        <div class="md:w-1/2 w-full">
          <label
            htmlFor="blog"
            className="font-raleway md:text-p2  md:leading-none sm:text-tp2 sm:leading-tp160 sm:tracking-[0.02em] md:tracking-normal leading-p180 text-mp2 text-neutral-600"
          >
            Search For Blog
          </label>
          <input
            type="text"
            name="blog"
            id="blog"
            className=" h-10 sm:h-[31px] md:h-10 border-b h focus:border-b outline-none text-p2 font-raleway text-neutral-900 focus:border-neutral-700 active:border-none  block w-full sm:text-sm border-neutral-200"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2   cursor-pointer  gap-14 sm:gap-x-10 sm:gap-y-16 md:gap-14">
          {blogs.map((bd, i) => (
            <IndividualBlog
              key={i}
              slug={slugify(bd?.title, bd?.date.split(" ")[0])}
              blogText={bd?.summary}
              blogImage={bd?.banner_image}
              blogHeader={bd?.title}
              writtenBy={bd?.author}
              category={bd?.category}
              author={bd?.author}
              authorImage={bd?.author_image}
              publishDate={bd?.date}
              variants={blogVarians}
              initial={"initial"}
              whileInView={"final"}
              custom={i}
              viewport={{ once: true }}
            />
          ))}
        </div>
      </StyledBlog>
      <div className="flex justify-end items-center mt-20 space-x-14 px-24 my-20">
        <button className="text-primary-300 border border-primary-700 border-dashed p-2 rounded-full h-max">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            className="fill-primary-300 "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.3482 32.6519L8.97778 19.2815L22.3482 5.91112L24.22 7.8164L12.7549 19.2815L24.22 30.7466L22.3482 32.6519Z"
              fill="current"
            />
          </svg>
        </button>
        <button className="text-primary-300 border border-primary-700 border-dashed p-2 rounded-full h-max">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            className="fill-primary-300 "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8719 32.6519L14 30.7466L25.4651 19.2815L14 7.8164L15.8719 5.91112L29.2422 19.2815L15.8719 32.6519Z"
              fill="current"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
const StyledBlog = styled.div``;
