import styled from "styled-components";
import { useRef } from "react";
import { motion } from "framer-motion";

//
import useMediaQuery from "../../hooks/useMediaQuery";
import { ideaIntro } from "../../constants/about";

//

export const Introduction = () => {
  const isMobile = useMediaQuery("(max-width:430px)");

  const gtm = useMediaQuery("(min-width:430px");
  const ltl = useMediaQuery("(max-width:1024px)");
  const isTab = gtm && ltl;
  const scrollRef = useRef(null);
  const variance = {
    animate: isMobile && { marginTop: 0 },
  };

  return (
    <StyledIntroduction className="md:grid md:grid-cols-4 md:h-[700px] md:overflow-y-hidden md:mt-40 py-16 md:gap-32 md:px-24 px-4  sm:px-8 sm:py-20 ">
      <motion.div
        className={`md:col-span-3 pb-16 sm:pb-20`}
        initial={{ marginTop: 204 }}
        whileInView={{ marginTop: 0 }}
        viewport={{ once: true }}
        transition={{ type: "tween", ease: "linear", duration: 1 }}
        ref={scrollRef}
      >
        <h2
          className={`md:text-h2 text-mh1 sm:text-th2 sm:leading-th120 sm:tmd:leading-l2 sm:tracking-[0.02em]  leading-mh1 tracking-wide text-neutral-800 font-sans capitalize    lg:text-5xl xl:text-6xl`}
        >
          {ideaIntro?.title}
        </h2>

        <motion.p
          initial={{ marginTop: "9.25rem" }}
          transition={{ type: "tween", ease: "linear", duration: 1 }}
          whileInView={{ marginTop: isMobile ? "1.5rem" : "1.75rem" }}
          viewport={{ once: true }}
          className={`
           text-neutral-900 mt-6 font-raleway sm:text-tp2  sm:leading-p160 sm:tracking-[0.02em] md:tracking-normal md:mt-36 md:opacity-100 md:text-justify md:leading-intro md:text-p3 text-p4 opacity-80`}
        >
          {" "}
          {ideaIntro.description_1}
        </motion.p>
        <motion.p
          initial={{ marginTop: "13.5rem" }}
          whileInView={{ marginTop: isMobile ? "1.5rem" : "4rem" }}
          viewport={{ once: true }}
          className={` text-neutral-900 mt-6 font-raleway sm:text-tp2  sm:leading-p160 sm:tracking-[0.02em] md:tracking-normal md:mt-36 md:opacity-100 md:text-justify md:leading-intro md:text-p3 text-p4 opacity-80`}
          transition={{ type: "tween", ease: "linear", duration: 1 }}
        >
          {ideaIntro.description_2}
        </motion.p>
      </motion.div>

      <motion.div
        className={`md:col-span-1`}
        initial={{ marginTop: isMobile ? "20px" : 364 }}
        whileInView={{ marginTop: 4 }}
        {...variance}
        viewport={{ once: true }}
        transition={!isMobile && { type: "tween", ease: "linear", duration: 1 }}
        ref={scrollRef}
      >
        <div className={`${isMobile || isTab ? "flex flex-row gap-4" : ""}`}>
          {ideaIntro.stat.map((intrd, id) => (
            <div className="md:mb-20 basis-1/3" key={id}>
              <h2 className="md:text-h2 sm:text-th2 sm:leading-th120 text-mh1 md:leading-h2 leading-mh1 tracking font-lora  text-primary-900 ">
                {intrd.data}
              </h2>
              <h4 className="md:text-h4 sm:text-th4 sm:leading-th140 sm:tracking-[0.01rem] font-lora md:leading-h2  md:text-shades-100 text-mh4 text-neutral-800 ">
                {intrd.title}
              </h4>
            </div>
          ))}
          {/* <div className="md:mb-20 basis-1/3">
            <h2 className="md:text-h2 sm:text-th2 sm:leading-th120 text-mh1 md:leading-h2 leading-mh1  font-lora   text-primary-900 ">
              45
            </h2>
            <h4 className="md:text-h4 sm:text-th4 sm:leading-th140 sm:tracking-[0.01rem] font-lora md:leading-h2  md:text-shades-100 text-mh4 text-neutral-800 ">
              Products
            </h4>
          </div>
          <div className="mb-16 basis-1/3">
            <h2 className="md:text-h2 sm:text-th2 sm:leading-th120 text-mh1 font-lora md:leading-h2 leading-mh1 tracking   text-primary-900 ">
              30
            </h2>
            <h4 className="md:text-h4 sm:text-th4 sm:leading-th140 sm:tracking-[0.01rem] font-lora md:leading-h2  md:text-shades-100 text-mh4 text-neutral-800 ">
              In House Talent
            </h4>
          </div> */}
        </div>
      </motion.div>
    </StyledIntroduction>
  );
};

const StyledIntroduction = styled(motion.div)``;
