import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function HeroJoin() {
  const navigate = useNavigate();
  return (
    <StyledJoinUs className="col-span-3 overflow-hidden md:p-7 px-4 py-6 sm:py-4   bg-primary-900 group cursor-pointer relative">
      <h3 className="md:text-h3 font-lora md:leading-h3 sm:text-th4 sm:leading-th140 text-center text-mh3 leading-mh3 relative text-neutral-50 duration-200 transition  bg-transparent group">
        If you are the one who wants to work remote then,
      </h3>
      <p className="font-raleway md:text-l1 sm:text-tl14 sm:leading-th140 sm:tracking-[0.02em] md:leading-l1 text-ml3 leading-mh3 md:tracking-normal tracking-wider mt-4  text-neutral-50 text-center">
        Join our awesome team
      </p>
      <div
        className="flex justify-center md:py-12 py-8 cursor-pointer"
        onClick={() => navigate("/careers")}
      >
        <button className="h-[68px] w-[68px]  sm:h-20 sm:w-20  md:p-5 flex justify-center items-center join_btn border-2 sm:text-tl3  sm:leading-th140 sm:tracking-[0.02em] md:tracking-normal  md:text-l2 font-raleway text-l3   text-shades-0  transition duration-200 rounded-full relative">
          {" "}
          JOIN
        </button>
      </div>
    </StyledJoinUs>
  );
}

const StyledJoinUs = styled.div`
  .join_btn::after,
  .join_btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 50%;
    opacity: 0;
    transition: linear 200ms;
    outline: 1px dashed white;
  }

  .join_btn:hover::before {
    outline-offset: 16px;
    outline: 1px dashed white;
    opacity: 1;
  }
  .join_btn:hover::after {
    outline-offset: 32px;
    outline: 1px dashed white;
    opacity: 1;
  }
`;
