/**
 *
 *
 */
const VisitOffice = ({ idea }) => {
  return (
    <div className="md:flex sm:gap-x-2 mb-16  pt-6 sm:pt-14">
      <div className=" basis-1/2">
        <div>
          <h4 className="md:text-h4 sm:text-th4 sm:tracking-[0.01em] text-mh3  leading-mh140 font-lora text-neutral-800">
            Address
          </h4>
          <p className="md:text-p2 text-mp2 sm:text-tp3  leading-p180 font-raleway text-neutral-600 mt-2">
            {idea.tol}
            <br /> {idea.place},
            <br /> {idea.district + idea.country}
          </p>
        </div>
        <div className="mt-10">
          <h4 className="md:text-h4 text-mh3 sm:text-th4 tracking-[0.01em] md:tracking-normal leading-mh140 font-lora text-neutral-800">
            Contact
          </h4>
          <p className="md:text-p2 text-mp2 sm:text-tp3 leading-p180 font-raleway text-neutral-600 mt-2">
            {idea.phone}
            <br /> {idea.email}
          </p>
        </div>
        <div className="mt-10 sm:mb-10">
          <h4 className="md:text-h4 text-mh3 sm:text-th4 sm:tracking-[0.02em] md:tracking-normal leading-mh140 font-lora text-neutral-800">
            Business Hours
          </h4>
          <p className="md:text-p2 text-mp2 sm:text-tp3  leading-p180 font-raleway text-neutral-600 mt-2">
            {idea.business_hour}
            <br /> {idea.working_hour}
          </p>
        </div>
      </div>
      <div className="sm:basis-1/2 w-full mt-10 sm:mt-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d742.7380042862708!2d85.33470536804553!3d27.684006360166872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19955d3b4015%3A0xe4aa314127ee2733!2sIdea%20Breed%20Technology!5e0!3m2!1sen!2snp!4v1673258930249!5m2!1sen!2snp"
          width="600"
          height="450"
          className="border-none w-full"
          allowfullscreen=""
          loading="lazy"
          title="Ideabreed"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default VisitOffice;
